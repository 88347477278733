<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Item Supplier</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Supplier / Item Supplier</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="card bg-white pt-4">
          <form @submit.prevent="handleSubmit(!v$.$invalid)">
            <div class="field col-12">
              <div class="formgrid grid">
                <div class="field col-12 md:col-4">
                  <label>Supplier</label>
                  <Dropdown
                    v-model="supp_id"
                    :filter="true"
                    :options="list_supplier"
                    class="w-full"
                    input-class="w-full"
                    :class="{ 'p-invalid': supp_id == 0 && submitted }"
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Pilih Supplier"
                    @change="loadData"
                  />
                  <small v-if="supp_id == 0 && submitted" class="p-error"
                >Supplier harus dipilih.</small
              >
                </div>
                <div class="field col-12 md:col-4">
                  <label>Item</label>
                  <AutoComplete
                    v-model="selectedItem"
                    ref="item"
                    class="w-full"
                    :class="{ 'p-invalid': item_id == 0 && submitted }"
                    input-class="w-full"
                    :suggestions="listItems"
                    field="name"
                    @complete="searchItem"
                    @item-select="selectItem" 
                    @keydown.enter="onAddData"                                  
                  >
                    <template #item="slotProps">
                      <div>
                        <b>{{ slotProps.item.sku }}</b>
                      </div>
                      <div>{{ slotProps.item.name }}</div>
                    </template>
                  </AutoComplete>                  
                  <small v-if="item_id == 0 && submitted" class="p-error"
                >Item harus dipilih.</small>
                </div>
                <div class="field col-12 md:col-2">
                  <label> &nbsp; </label>
                    <Button
                      icon="pi pi-plus"
                      label="Tambah Item"
                      class="w-full"
                      @click="onAddData"
                    />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card bg-white">
      <fx-table
        :items="itemsuppliers"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="item_sku"
            header="SKU"
            style="min-width: 12rem"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="item_name"
            header="Nama Item"
            style="min-width: 15rem"
            sortable
            :filter-match-mode-options="containModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      header="Hapus Item dari Supplier"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ form.item_name }}</strong> akan dihapus dari list item supplier <strong>{{ form.supplier }}</strong>. <p>Proses ?</p></span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDelete"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SupplierService from '@/services/SupplierService'
import ItemService from '@/services/ItemService'
import ItemSupplierService from '@/services/ItemSupplierService'
import errorHandler from '@/helpers/error-handler'
import { FilterMatchMode } from 'primevue/api'
import FxTable from '@/components/FxTable'
import Hotkey from '@/components/Hotkey'

export default {
  name: 'ItemSupplier',
  components: {
    FxTable,
    Hotkey,
  },
  data() {
    return {
      supplierService: null,
      itemSupplierService: null,
      divisiService: null,
      list_supplier: [],
      list_item: [],
      itemsuppliers: [],
      listItems: [],
      selectedItem: [],
      query: null,
      form: {},
      submitted: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      supp_id: 0,
      item_id: 0,
      item_name: null,
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'id',
        sortOrder: '1',
        filters: {
          item_sku: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
        },
      },
      filters: {
        item_sku: { value: '', matchMode: FilterMatchMode.CONTAINS },
        item_name: { value: '', matchMode: FilterMatchMode.CONTAINS },
      },
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  created() {
    this.supplierService = new SupplierService()
    this.itemService = new ItemService()
    this.itemSupplierService = new ItemSupplierService()
  },
  async mounted() {
    this.supplierService
      .get()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.itemService
      .getList('')
      .then((res) => {
        this.listItems = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Item', this)
      })
      .finally(() => (this.isLoading = false))

    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder

      const filters = this.options.filters
      this.itemSupplierService
        .paginatebysupplier(
          page,
          perPage,
          sortField,
          sortOrder,
          filters,
          this.supp_id
        )
        .then((res) => {
          this.itemsuppliers = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data item supplier', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    selectItem() {
      this.item_id = this.selectedItem.id
      this.item_name = this.selectedItem.name
    },
    searchItem(event) {
      this.itemService
        .getList(event.query)
        .then((res) => {
          this.listItems = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Item', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onAddData() {
      this.submitted = true
      if (this.supp_id === null || this.supp_id === 0) return
      if (this.item_id === null || this.item_id === 0) return
      this.isLoadingSave = true
      const item = {}
      item.supp_id = this.supp_id
      item.item_id = this.item_id

      this.itemSupplierService
        .add(item)
        .then((res) => {
          if (res.data.status === 1) {
            this.loadData()
            this.$toast.add({
              severity: 'success',
              summary: 'Data item supplier',
              detail: 'Data item berhasil ditambahkan.',
              life: 3000,
            })
            this.dialog = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data item supplier', this)
        })
        .finally(() => {
          this.isLoadingSave = false
          this.item_id = 0
          this.selectedItem = null
          this.submitted = false
          this.$refs.item.$el.querySelector('input').focus()
        })
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDelete() {
      this.itemSupplierService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Supplier item',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier item', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
